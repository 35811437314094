<template>
  <div>
    <b-card title="Что это такое?!">
      <b-card-text>Мы просто ничего не придумали, что можно было бы сюда написать.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  metaInfo: {
    title: 'Главная',
  },
}
</script>

<style>

</style>
